var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"timeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dateRange,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","outlined":"","dense":"","label":"Date Range","placeholder":" "},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateRangeMenu),callback:function ($$v) {_vm.dateRangeMenu=$$v},expression:"dateRangeMenu"}},[(_vm.dateRangeMenu)?_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},on:{"change":function($event){return _vm.getAllGamesInThis(_vm.dateRange)}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.teamRows,"options":_vm.tableOptions,"hide-default-footer":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.parseHeader(header))+" ")]}}}),{key:"body",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item){return _c('tr',{key:item.id,class:{'primary': item.id === _vm.selectedId},on:{"click":function($event){return _vm.activeRow(item.id)}}},_vm._l((_vm.headers),function(header){return _c('td',{key:header.value},[(header.text === 'Team')?_c('v-btn',{attrs:{"icon":"","x-large":""}},[_c('v-img',{attrs:{"contain":"","src":("/logos/" + (item.id) + ".png"),"height":"30"}})],1):_vm._e(),(header.text === '# of Games')?_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")]):_vm._e(),(typeof item[header.value] === 'object')?_c('v-btn',{attrs:{"icon":"","x-large":""}},[_c('v-img',{attrs:{"contain":"","src":("/logos/" + (_vm.getOpponentId(item.id, item[header.value])) + ".png"),"height":"30"}})],1):_vm._e()],1)}),0)})}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }