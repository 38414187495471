<template>
    <v-btn
      fab small
      bottom
      right
      color="blue"
      absolute
      style="margin: 0 0 25px 0"
    >
      <v-icon>
        fa-globe-americas
      </v-icon>
    </v-btn>
</template>

<script>
export default {
  name: 'MobileMapListToggle',
  props: {},
  components: {},
  data() {
    return {
      //
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style scoped>

</style>
